import { NextPage } from "next";
import { Index } from "../components/Index";
import { Layout } from "../components/Layout";

const Home: NextPage = () => {
  return (
    <Layout title="地域・教育魅力化プラットフォーム">
      <Index />
    </Layout>
  );
};

export default Home;

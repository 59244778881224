import { useUser } from "@auth0/nextjs-auth0";
import Link from "next/link";
import { VFC } from "react";

export const Index: VFC = () => {
  const { user, error, isLoading } = useUser();
  return (
    <div className="home-page">
      <section className="home-kv">
        <div className="home-kv__bg u-pc">
          <span
            style={{ backgroundImage: "url(/assets/img/home/kv-bg.jpg)" }}
          ></span>
        </div>
        <div className="home-kv__bg u-sp">
          <span
            style={{ backgroundImage: "url(/assets/img/home/kv-bg-sp.jpg)" }}
          ></span>
        </div>
        <div className="home-kv__inner">
          <div className="home-kv__inner2">
            <div className="home-kv__info">
              <h2 className="home-kv__ttl">
                <img
                  src="/assets/img/home/kv-ttl.svg"
                  alt="地域の「みらい」をつくる、意志ある若者の未来のために。"
                />
              </h2>
              <p className="home-kv__lead">
                <img
                  src="/assets/img/home/kv-lead.svg"
                  alt="変化が激しく正解のない社会を生きていくために、自分の意志で挑戦を続け、自ら未来をつくる若者を、多様性あふれる地域で育んでみませんか。"
                />
              </p>
              <div className="home-kv__btn">
                <p className="c-btn -bl -homeKv">
                  <Link href="/entry">
                    <a target="_blank" className="u-over" rel="noreferrer">
                      寄付で応援する
                    </a>
                  </Link>
                </p>
              </div>
              <p className="home-kv__support">
                <img src="/assets/img/home/kv-support.svg" alt="応援委員会" />
                <br />
              </p>
            </div>
          </div>
        </div>
        <p className="home-kv__fix">
          <Link href={user ? "/mypage/change_donation" : "/entry"}>
            <a className="u-over">
              <img
                className="u-pc"
                src="/assets/img/home/kv-fix.png"
                alt="寄付で応援する"
              />
              <img
                className="u-sp"
                src="/assets/img/home/kv-fix-sp.png"
                alt="寄付で応援する"
              />
            </a>
          </Link>
        </p>
      </section>

      <section className="home-topMsg">
        <div className="l-container">
          <div className="home-topMsg__inner">
            <div className="home-pTtl">
              <h2 className="home-pTtl__txt">
                <span>
                  <b>
                    一日約３３円で、意志ある若者の育成と
                    <br />
                    日本の地域への支援ができます。
                  </b>
                </span>
              </h2>
            </div>

            <div className="home-pTtl2">
              <h3 className="home-pTtl2__txt">
                <span>ご寄付の活用先</span>
              </h3>
            </div>

            <div className="home-topMsg__cols">
              <p className="home-topMsg__img">
                <img src="/assets/img/home/topmsg-img1.jpg" alt="" />
              </p>
              <div className="home-topMsg__txts">
                <h4>
                  <img
                    src="/assets/img/home/topmsg-price1.svg"
                    alt="毎月3,000円のご寄付で"
                  />
                </h4>
                <p>
                  地域みらい留学生3名、
                  <br className="u-sp" />
                  留学実現のサポートができます
                </p>
                <h4>
                  <img
                    src="/assets/img/home/topmsg-price2.svg"
                    alt="毎月5,000円のご寄付で"
                  />
                </h4>
                <p>
                  地域みらい留学生1名、
                  <br className="u-sp" />
                  地域での探究学習を1年間サポートできます
                </p>
              </div>
            </div>

            <div className="home-pBtns">
              <div className="home-pBtns__btn">
                <p className="c-btn -bl -home">
                  <Link href="/entry">
                    <a target="_blank" className="u-over" rel="noreferrer">
                      寄付で応援する
                    </a>
                  </Link>
                </p>
              </div>
              <div className="home-pBtns__btn">
                <p className="c-btn -bl -home">
                  <a href="/pdf/report.pdf" className="u-over" target="_blank">
                    報告書を見る
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-graph">
        <div className="l-container">
          <div className="home-graph__inner">
            <div className="home-pTtl">
              <h2 className="home-pTtl__txt">
                <span>
                  <b>
                    日本の若者は海外の若者よりも、
                    <br />
                    将来への期待が低い傾向があります。
                  </b>
                </span>
              </h2>
            </div>

            <div className="home-pTtl2">
              <h3 className="home-pTtl2__txt">
                <span>日本の若者の現状</span>
              </h3>
            </div>

            <p className="home-graph__img1">
              <img
                src="/assets/img/home/graph-img1.png"
                alt="18歳意識調査「社会や国に対する意識調査」2019 日本財団"
              />
            </p>

            <div className="home-pTtl2">
              <h3 className="home-pTtl2__txt">
                <span>なりゆきの未来</span>
              </h3>
            </div>

            <div className="home-graph__colsWrap">
              <div className="home-graph__cols">
                <p className="home-graph__colImg">
                  <img
                    src="/assets/img/home/graph-img2-1.jpg"
                    alt="Population"
                  />
                </p>
                <div className="home-graph__colTxt">
                  <h4>人口減少社会</h4>
                  <p>
                    2060年には、日本の人口は現在の3分の2。超高齢化社会に突入し、労働者人口は大幅に減少。
                  </p>
                </div>
              </div>

              <div className="home-graph__cols">
                <p className="home-graph__colImg">
                  <img src="/assets/img/home/graph-img2-2.jpg" alt="Social" />
                </p>
                <div className="home-graph__colTxt">
                  <h4>急激な社会変化</h4>
                  <p>
                    財政赤字、国際競争力の低下、子育て・女性の社会進出など、解決すべき課題が山積み。
                  </p>
                </div>
              </div>

              <div className="home-graph__cols">
                <p className="home-graph__colImg">
                  <img src="/assets/img/home/graph-img2-3.jpg" alt="Local" />
                </p>
                <div className="home-graph__colTxt">
                  <h4>地域社会の衰退</h4>
                  <p>
                    市区町村の合併や学校の統廃校、それに伴う家族・若者の流出など、悪循環が拡がる。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-create">
        <div className="l-container">
          <div className="home-create__inner">
            <div className="home-pTtl">
              <h2 className="home-pTtl__txt">
                <span>
                  <b>
                    意志ある若者の育成と
                    <br className="u-sp" />
                    持続可能な地域・社会を
                    <br />
                    「高校魅力化」を通じてつくっていきます。
                  </b>
                </span>
              </h2>
            </div>

            <div className="home-createSlider">
              <div className="home-createSlider__slick">
                <div className="home-createSlider__slickSlide">
                  <div className="home-createSlider__slide">
                    <div className="home-pTtl2">
                      <h3 className="home-pTtl2__txt">
                        <span>
                          高校魅力化を起点とした
                          <br className="u-sp" />
                          人づくりの好循環
                        </span>
                      </h3>
                    </div>
                    <p className="home-createSlider__img">
                      <img src="/assets/img/home/create-slide1.png" alt="" />
                    </p>
                  </div>
                </div>
                <div className="home-createSlider__slickSlide">
                  <div className="home-createSlider__slide">
                    <div className="home-pTtl2">
                      <h3 className="home-pTtl2__txt">
                        <span>地方に拡がる悪循環</span>
                      </h3>
                    </div>
                    <p className="home-createSlider__img">
                      <img src="/assets/img/home/create-slide2.png" alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-nurture">
        <div className="l-container">
          <div className="home-nurture__inner">
            <div className="home-pTtl">
              <h2 className="home-pTtl__txt">
                <span>
                  <b>
                    「地域みらい留学」などの活動を通じて
                    <br />
                    意志ある若者の育成を目指します。
                  </b>
                </span>
              </h2>
            </div>

            <div className="home-pTtl2">
              <h3 className="home-pTtl2__txt">
                <span>私たちの取り組み</span>
              </h3>
            </div>

            <div className="home-nurture__colsWrap">
              <div className="home-nurture__cols">
                <p className="home-nurture__colsImg">
                  <img src="/assets/img/home/nurture-img1-1.jpg" alt="" />
                </p>
                <div className="home-nurture__colsTxts">
                  <h3>地域みらい留学</h3>
                  <p>
                    どこで何を学ぶかは、自分で決める。都道府県の枠を越えた新しい高校進学「地域みらい留学」を運営しています。全国の地域みらい留学校が一同に集結する「合同学校説明会」では、学校の魅力を活かしたテーマ別説明会や、地域みらい留学生や、その親が語る経験者対談など、新たな出会いの場づくりをしています。
                  </p>
                </div>
              </div>
              <div className="home-nurture__cols">
                <p className="home-nurture__colsImg">
                  <img src="/assets/img/home/nurture-img1-2.jpg" alt="" />
                </p>
                <div className="home-nurture__colsTxts">
                  <h3>地域みらい留学365</h3>
                  <p>
                    内閣府と協働し、高校2年生の1年間の国内単年留学「地域みらい留学365」を運営しています。新たに出会う友達や世代を越えた多くの仲間たちと共に、地域ならではの魅力を肌で感じ、生きた課題に向き合う1年間。こうした経験を通し、その後の高校生活や人生にも繋がるような、「自らみらいを創る力」を育みます。
                  </p>
                </div>
              </div>
              <div className="home-nurture__cols">
                <p className="home-nurture__colsImg">
                  <img src="/assets/img/home/nurture-img1-3.jpg" alt="" />
                </p>
                <div className="home-nurture__colsTxts">
                  <h3>高校魅力化プロジェクト</h3>
                  <p>
                    学校と地域が協働することで、生徒にとっての「魅力ある学びの場づくり」を目指す高校魅力化プロジェクト。その鍵となるコーディーネーターの採用・育成サポートや、高校魅力化の指標・評価サイクルづくり、高校生の探究学習支援サポートなどを行っています。
                  </p>
                </div>
              </div>
            </div>

            <div className="home-nurture__cols2">
              <div className="home-nurture__cols2Col">
                <div className="home-pTtl2 -col">
                  <h3 className="home-pTtl2__txt">
                    <span>地域みらい留学参画校の推移</span>
                  </h3>
                </div>
                <p className="home-nurture__cols2Img">
                  <img src="/assets/img/home/nurture-img2-1.png" alt="" />
                </p>
              </div>
              <div className="home-nurture__cols2Col">
                <div className="home-pTtl2 -col">
                  <h3 className="home-pTtl2__txt">
                    <span>合同学校説明会の来場者の推移</span>
                  </h3>
                </div>
                <p className="home-nurture__cols2Img">
                  <img src="/assets/img/home/nurture-img2-2.png" alt="" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-voice">
        <div className="l-container">
          <div className="home-voice__inner">
            <div className="home-pTtl">
              <h2 className="home-pTtl__txt">
                <span>
                  <b>「地域みらい留学」卒業生の声。</b>
                </span>
              </h2>
            </div>

            <div className="home-voice__cols">
              <div className="home-voice__colsCol">
                <h3>
                  島根県/津和野高校 卒業
                  <br />
                  鈴木 元太さん（神奈川県出身）
                </h3>
                <p className="home-voice__colsImg">
                  <img src="/assets/img/home/voice-img1.jpg" alt="" />
                </p>
                <div className="home-voice__colsTxt">
                  <p>
                    都会にいると機会が少ないですが、津和野では行政の方や農家の方、Iターンして地域おこしに取り組む方、街の和菓子屋さんなど、いろんな職業の方と話す機会がたくさんあるので、仕事に対するリアリティも持てるようになりました。今は学びたいことがたくさんありすぎて、時間が足りないくらい。将来の選択肢もどんどん広がっています。
                  </p>
                </div>
              </div>
              <div className="home-voice__colsCol">
                <h3>
                  広島県/大崎海星高校 卒業
                  <br />
                  細川 真住さん（東京都出身）
                </h3>
                <p className="home-voice__colsImg">
                  <img src="/assets/img/home/voice-img2.jpg" alt="" />
                </p>
                <div className="home-voice__colsTxt">
                  <p>
                    大崎海星高校に進学してよかったと思うのは、魅力的な大人の人が多いこと。満員電車で疲れている人ばかり見てしまうと、私も将来どうなるだろうって心配になることもありましたが、ここでは日々楽しそうに活動している人がたくさんいる。そんな人たちから話を聞く機会も多いので、「働くって面白そう」と思うようになりました。
                  </p>
                </div>
              </div>
              <div className="home-voice__colsCol">
                <h3>
                  島根県/隠岐島前高校 卒業
                  <br />
                  前田 陽汰さん（東京都出身）
                </h3>
                <p className="home-voice__colsImg">
                  <img src="/assets/img/home/voice-img3.jpg" alt="" />
                </p>
                <div className="home-voice__colsTxt">
                  <p>
                    隠岐島前高校を卒業して、私は今、思想家であり起業家である立場から「日本社会の右肩上がりを問い直す」というテーマに取り組んでいます。何事も変化し続け、終わりへ向かうという変化もまた自然であるという解釈です。消えゆくこと、終わりゆくこと、死にゆくことに焦点を当てた研究活動や事業を行っています。
                  </p>
                </div>
              </div>
            </div>

            <div className="home-pBtns">
              <div className="home-pBtns__btn">
                <p className="c-btn -bl -home">
                  <Link href="/entry">
                    <a target="_blank" className="u-over" rel="noreferrer">
                      寄付で応援する
                    </a>
                  </Link>
                </p>
              </div>
              <div className="home-pBtns__btn">
                <p className="c-btn -bl -home">
                  <a href="/pdf/report.pdf" className="u-over" target="_blank">
                    報告書を見る
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-event">
        <div className="l-container">
          <div className="home-event__inner">
            <div className="home-pTtl3 -wt">
              <h2 className="home-pTtl3__txt">
                <span>オンラインイベント</span>
              </h2>
            </div>

            <div className="home-eventSlider">
              <div className="home-eventSlider__slick">
                <div className="home-eventSlider__slickSlide">
                  <div className="home-eventSlider__slide">
                    <a
                      href="https://c-edu-platform.peatix.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="home-eventSlider__img">
                        <img src="/assets/img/home/event-slide1.jpg" alt="" />
                      </p>
                    </a>
                  </div>
                </div>
                <div className="home-eventSlider__slickSlide">
                  <div className="home-eventSlider__slide">
                    <a
                      href="https://c-edu-platform.peatix.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="home-eventSlider__img">
                        <img src="/assets/img/home/event-slide2.jpg" alt="" />
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-report">
        <div className="l-container">
          <div className="home-report__inner">
            <div className="home-report__cols">
              <p className="home-report__colsImg">
                <img src="/assets/img/home/report-img1.png" alt="" />
              </p>
              <div className="home-report__colsTxts">
                <h3>活動報告書（2017−2020）</h3>
                <p>
                  教育関係者のインタビュー、地域みらい留学について、コーディネート機能について、代表理事
                  岩本悠／理事会長
                  水谷智之のメッセージ、メンバーメッセージ、活動実績、財務諸表などを報告いたします。
                </p>
              </div>
            </div>

            <div className="home-report__btn">
              <p className="c-btn -bl -home">
                <a href="/pdf/report.pdf" className="u-over" target="_blank">
                  報告書を見る
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-support">
        <div className="home-support__inner">
          <div className="l-container">
            <p className="home-support__ttl1">応援委員会に入りませんか？</p>
            <h2 className="home-support__ttl2">
              <img
                src="/assets/img/home/support-ttl.svg"
                alt="地域みらい留学 応援委員会"
              />
            </h2>
            <p className="home-support__lead">
              多様性のある地域の魅力的な学びを促進し、
              <br className="u-sp" />
              さらに共感と応援の輪を広げるために
              <br />
              継続的に応援していただける
              <br className="u-sp" />
              寄付会員を募集しています。
            </p>

            <div className="home-pTtl -border">
              <h2 className="home-pTtl__txt">
                <span>
                  <b>ご支援者の声</b>
                </span>
              </h2>
            </div>

            <div className="home-support__inner2 u-pc">
              <p className="home-support__catch">
                異質との出会いこそが人を育てる。
                <br />
                さあ、「当たり前」の世界から飛び出そう。
              </p>
              <div className="home-support__cols">
                <p className="home-support__colsImg">
                  <img src="/assets/img/home/support-img1.jpg" alt="" />
                </p>
                <div className="home-support__colsTxts">
                  <h3>
                    株式会社フューチャー・デザイン・ラボ
                    <br />
                    代表取締役会長 <b>竹原 啓二氏</b>
                  </h3>
                  <p>
                    「地域みらい留学」の面白いところは、地域社会に高校生を巻き込んでいくという在り方です。これまでは、高校生と社会人が一緒になって地域で活動するという発想も機会もなかった。その背景には、社会を動かすのは大人だ、高校生はまだ子どもだという先入観があって、無意識に線引きをしていたのだと思います。でも、よく考えたら、高校生が混じって一緒に議論したっていいし、大人たちが見て見ぬ振りをしてきた問題や形骸化した常識に対して高校生が意見するということもあるだろうし、いいじゃないかと。むしろ、高校生のパワーを使わない手はないですよね。このコペルニクス的転回がすごくいい。これは、日本の教育において、大きなチャレンジだと思います。
                  </p>
                </div>
              </div>
              <hr />
              <p className="home-support__catch">
                人生のオーナーシップは自分が握る。
                <br />
                決められた枠を超え、異質と出会い、人とつながろう。
              </p>
              <div className="home-support__cols">
                <p className="home-support__colsImg">
                  <img src="/assets/img/home/support-img2.jpg" alt="" />
                </p>
                <div className="home-support__colsTxts">
                  <h3>
                    一般財団法人100万人のクラシックライブ
                    <br />
                    代表理事 <b>蓑田 秀策氏</b>
                  </h3>
                  <p>
                    都会のように社会の枠組みがカチッと決まっているところに住んでいると、その決められた枠の中で過ごすしかなくなり、子どもたちのなかに無力感が生まれてしまいます。いわば、窮屈な箱の中に閉じ込められたような状態です。一方、地方はその枠がゆるくて遊びの部分があるから、子どもは外に向けてエネルギーを発散させられるんです。この違いはとても大きいと思います。枠から飛び出して異質なものと出会うことはとても大事です。「地域みらい留学」の意義も、そこにあると私は考えています。
                  </p>
                </div>
              </div>
              <hr />
              <p className="home-support__catch">
                高校時代に大切なのは、裾野を広げておくこと。 <br />
                異質なものと対話する力は、世界を救う。
              </p>
              <div className="home-support__cols">
                <p className="home-support__colsImg">
                  <img src="/assets/img/home/support-img3.jpg" alt="" />
                </p>
                <div className="home-support__colsTxts">
                  <h3>
                    日本たばこ産業（JT）株式会社
                    <br />
                    取締役会長 <b>岩井 睦雄氏</b>
                  </h3>
                  <p>
                    「地域みらい留学」のよいところは、自分が学んでいることがどう社会とつながるかを肌で感じられることです。地域のコミュニティや人とかかわりながら学ぶことで、冒頭で述べた「自分は世の中にどう貢献できるか」という意識が芽生えると思うんです。ビジネスでは、学校で学んできたことがそのまま仕事に活かせることは少なく、課題解決に必要なスキルや知識をその時々で新しく学んでいきます。一方、学校ではこれまで、いい大学に入ることが勉強の目的になっていて、両者の学び方にギャップがありました。高校生が実際の地域の課題に取り組むプロジェクト学習というのは、社会人型の学びなので、実際に社会に出たときにも大いに役立つと思います。
                  </p>
                </div>
              </div>
              <hr />
              <p className="home-support__catch">
                同質を集めて枠にはめる時代は終わり。 <br />
                異質と出会い多様性のなかでもまれ、
                感じ考える体験が、人を育てる。
              </p>
              <div className="home-support__cols">
                <p className="home-support__colsImg">
                  <img src="/assets/img/home/support-img4.jpg" alt="" />
                </p>
                <div className="home-support__colsTxts">
                  <h3>
                    アクサ生命保険株式会社
                    <br />
                    代表取締役社長 兼 CEO <b>安渕 聖司氏</b>
                  </h3>
                  <p>
                    都会の方が洗練されているとか学力が高いとか教育の質が高いとか、そうではないものをつくる。この動きがさらに拡大して、地域みらい留学が中学生にとって当たり前の選択肢の一つになることを願っています。どの時期にどういう助けを出せば人は変化するか、さまざまなフェーズがありますが、間違いなく高校３年間は大きい。人生を変えうる時期です。その時期にある子どもたちが、学業成績という物差しだけで自分を測るのではなく、探究型の学びや経験を通して自らの強みや特性を発見する3年間を過ごすことは、とても価値のあることだと思います。
                  </p>
                </div>
              </div>
              <hr />
              <p className="home-support__catch">
                居心地の良い世界を飛び出し、
                <br />
                人とかかわりリアルを知る体験を！
              </p>
              <div className="home-support__cols">
                <p className="home-support__colsImg">
                  <img src="/assets/img/home/support-img5.jpg" alt="" />
                </p>
                <div className="home-support__colsTxts">
                  <h3>
                    パーソルホールディングス
                    <br />
                    代表取締役副社長 <b>高橋 広敏氏</b>
                  </h3>
                  <p>
                    都会で暮らす高校生って、大人に出会う機会が極端に少ないですよね。大都市には人がたくさんいますが、集団としての塊が大きいため、物事の全体像が見えにくく物の側面や断片しか見えてこない。地域は人も多くないので、かかわりも深くなりやすい、そこで誰がどんな暮らしをしてるのか、何を考えているのか、誰が権限を持ち決めているのかが分かり易い。高校時代にそういうリアルな社会に触れる体験をすることって、とても大事だと思います。
                  </p>
                </div>
              </div>
            </div>

            <div className="home-support__inner2 u-sp">
              <div className="home-support__slider">
                <div className="home-support__slick">
                  <div className="home-support__slickSlide">
                    <div className="home-support__slide">
                      <p className="home-support__catch">
                        異質との出会いこそが人を育てる。
                        <br />
                        さあ、「当たり前」の世界から飛び出そう。
                      </p>
                      <div className="home-support__cols">
                        <p className="home-support__colsImg">
                          <img src="/assets/img/home/support-img1.jpg" alt="" />
                        </p>
                        <div className="home-support__colsTxts">
                          <h3>
                            株式会社フューチャー・デザイン・ラボ
                            <br />
                            代表取締役会長 <b>竹原 啓二氏</b>
                          </h3>
                          <p>
                            「地域みらい留学」の面白いところは、地域社会に高校生を巻き込んでいくという在り方です。これまでは、高校生と社会人が一緒になって地域で活動するという発想も機会もなかった。その背景には、社会を動かすのは大人だ、高校生はまだ子どもだという先入観があって、無意識に線引きをしていたのだと思います。でも、よく考えたら、高校生が混じって一緒に議論したっていいし、大人たちが見て見ぬ振りをしてきた問題や形骸化した常識に対して高校生が意見するということもあるだろうし、いいじゃないかと。むしろ、高校生のパワーを使わない手はないですよね。このコペルニクス的転回がすごくいい。これは、日本の教育において、大きなチャレンジだと思います。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home-support__slickSlide">
                    <div className="home-support__slide">
                      <p className="home-support__catch">
                        人生のオーナーシップは自分が握る。
                        <br />
                        決められた枠を超え、異質と出会い、人とつながろう。
                      </p>
                      <div className="home-support__cols">
                        <p className="home-support__colsImg">
                          <img src="/assets/img/home/support-img2.jpg" alt="" />
                        </p>
                        <div className="home-support__colsTxts">
                          <h3>
                            一般財団法人100万人のクラシックライブ
                            <br />
                            代表理事 <b>蓑田 秀策氏</b>
                          </h3>
                          <p>
                            都会のように社会の枠組みがカチッと決まっているところに住んでいると、その決められた枠の中で過ごすしかなくなり、子どもたちのなかに無力感が生まれてしまいます。いわば、窮屈な箱の中に閉じ込められたような状態です。一方、地方はその枠がゆるくて遊びの部分があるから、子どもは外に向けてエネルギーを発散させられるんです。この違いはとても大きいと思います。枠から飛び出して異質なものと出会うことはとても大事です。「地域みらい留学」の意義も、そこにあると私は考えています。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home-support__slickSlide">
                    <div className="home-support__slide">
                      <p className="home-support__catch">
                        高校時代に大切なのは、裾野を広げておくこと。 <br />
                        異質なものと対話する力は、世界を救う。
                      </p>
                      <div className="home-support__cols">
                        <p className="home-support__colsImg">
                          <img src="/assets/img/home/support-img3.jpg" alt="" />
                        </p>
                        <div className="home-support__colsTxts">
                          <h3>
                            日本たばこ産業（JT）株式会社
                            <br />
                            取締役会長 <b>岩井 睦雄氏</b>
                          </h3>
                          <p>
                            「地域みらい留学」のよいところは、自分が学んでいることがどう社会とつながるかを肌で感じられることです。地域のコミュニティや人とかかわりながら学ぶことで、冒頭で述べた「自分は世の中にどう貢献できるか」という意識が芽生えると思うんです。ビジネスでは、学校で学んできたことがそのまま仕事に活かせることは少なく、課題解決に必要なスキルや知識をその時々で新しく学んでいきます。一方、学校ではこれまで、いい大学に入ることが勉強の目的になっていて、両者の学び方にギャップがありました。高校生が実際の地域の課題に取り組むプロジェクト学習というのは、社会人型の学びなので、実際に社会に出たときにも大いに役立つと思います。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home-support__slickSlide">
                    <div className="home-support__slide">
                      <p className="home-support__catch">
                        同質を集めて枠にはめる時代は終わり。 <br />
                        異質と出会い多様性のなかでもまれ、
                        感じ考える体験が、人を育てる。
                      </p>
                      <div className="home-support__cols">
                        <p className="home-support__colsImg">
                          <img src="/assets/img/home/support-img4.jpg" alt="" />
                        </p>
                        <div className="home-support__colsTxts">
                          <h3>
                            アクサ生命保険株式会社
                            <br />
                            代表取締役社長 兼 CEO <b>安渕 聖司氏</b>
                          </h3>
                          <p>
                            都会の方が洗練されているとか学力が高いとか教育の質が高いとか、そうではないものをつくる。この動きがさらに拡大して、地域みらい留学が中学生にとって当たり前の選択肢の一つになることを願っています。どの時期にどういう助けを出せば人は変化するか、さまざまなフェーズがありますが、間違いなく高校３年間は大きい。人生を変えうる時期です。その時期にある子どもたちが、学業成績という物差しだけで自分を測るのではなく、探究型の学びや経験を通して自らの強みや特性を発見する3年間を過ごすことは、とても価値のあることだと思います。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home-support__slickSlide">
                    <div className="home-support__slide">
                      <p className="home-support__catch">
                        居心地の良い世界を飛び出し、
                        <br />
                        人とかかわりリアルを知る体験を！
                      </p>
                      <div className="home-support__cols">
                        <p className="home-support__colsImg">
                          <img src="/assets/img/home/support-img5.jpg" alt="" />
                        </p>
                        <div className="home-support__colsTxts">
                          <h3>
                            パーソルホールディングス
                            <br />
                            代表取締役副社長 <b>高橋 広敏氏</b>
                          </h3>
                          <p>
                            都会で暮らす高校生って、大人に出会う機会が極端に少ないですよね。大都市には人がたくさんいますが、集団としての塊が大きいため、物事の全体像が見えにくく物の側面や断片しか見えてこない。地域は人も多くないので、かかわりも深くなりやすい、そこで誰がどんな暮らしをしてるのか、何を考えているのか、誰が権限を持ち決めているのかが分かり易い。高校時代にそういうリアルな社会に触れる体験をすることって、とても大事だと思います。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-method">
        <div className="l-container">
          <div className="home-method__inner">
            <div className="home-pTtl">
              <h2 className="home-pTtl__txt">
                <span>
                  <b>お気持ちにあったご支援方法を。</b>
                </span>
              </h2>
            </div>

            <div className="home-method__lead">
              <p>
                月々1,000円からの「毎月の寄付」、
                <br className="u-sp" />
                ふるさと納税や直接の寄付による「今回の寄付」、
                <br />
                遺贈や相続、企業のCSR活動などによる
                <br className="u-sp" />
                支援方法をお選びください。
              </p>
            </div>

            <div className="home-methodTabs">
              <ul className="home-methodTabs__tabBtn">
                <li>
                  <a data-num="1" className="is-cr" href="javascript:void(0);">
                    毎月の寄付
                  </a>
                </li>
                <li>
                  <a data-num="2" className="" href="javascript:void(0);">
                    今回の寄付
                  </a>
                </li>
                <li>
                  <a data-num="3" className="" href="javascript:void(0);">
                    遺贈・その他
                  </a>
                </li>
              </ul>
              <div className="home-methodTabs__tabCons">
                <div className="home-methodTabs__tabCon is-cr">
                  <div className="home-methodTabs__tabConInner">
                    <h2>
                      日本の未来を支える
                      <br className="u-sp" />
                      意志ある若者へのご支援を
                    </h2>
                    <p className="home-methodTabs__iinkai">
                      <img
                        src="/assets/img/home/kv-support.svg"
                        alt="応援委員会"
                      />
                    </p>
                    <div className="home-methodTabs__txt">
                      <p>
                        意志ある若者の育成支援と、持続可能な日本の地域社会をつくっていくために。
                        <br className="u-pc" />
                        毎月1,000円からの継続的なご寄付で、ご支援いただけます。
                      </p>
                    </div>
                    <div className="home-methodTabs__cards">
                      <p className="home-methodTabs__cardsTtl">
                        お支払い方法は下記から
                        <br className="u-sp" />
                        お選びいただけます。
                      </p>
                      <div className="home-methodTabs__cardsCols">
                        <div className="home-methodTabs__cardsColsCol">
                          <p className="-ttl">クレジットカード</p>
                          <p className="-txt1">お使いいただけるカード</p>
                          <p className="-cards">
                            <img
                              src="/assets/img/common/icon-cards.png"
                              alt=""
                            />
                          </p>
                        </div>
                        <div className="home-methodTabs__cardsColsCol">
                          <p className="-ttl">口座振替</p>
                          <p className="-txt1">
                            各種銀行口座（ゆうちょ銀行含む）
                          </p>
                          <p className="-txt2">
                            ＊ご登録の住所に手続き用紙をお送りします。
                            <br />
                            記入・捺印のうえ10日以内に
                            <br className="u-sp" />
                            ご返送をお願いします。
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="home-methodTabs__btn">
                      <p className="c-btn -bl -home">
                        <Link href="/entry">
                          <a
                            target="_blank"
                            className="u-over"
                            rel="noreferrer"
                          >
                            「毎月のご寄付」に申し込む
                          </a>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="home-methodTabs__tabCon">
                  <div className="home-methodTabs__tabConInner">
                    <h2>
                      今すぐにご支援いただく
                      <br className="u-sp" />
                      「今回の寄付」。
                    </h2>

                    <div className="home-pTtl2">
                      <h3 className="home-pTtl2__txt">
                        <span>
                          地域・教育魅力化
                          <br className="u-sp" />
                          プラットフォームへの
                          <br className="u-sp" />
                          直接のご寄付
                        </span>
                      </h3>
                    </div>

                    <div className="home-methodTabs__txt">
                      <p>
                        意志ある若者の育成支援と、持続可能な日本の地域社会をつくっていくために。
                        <br className="u-pc" />
                        1,000円からのご寄付で、ご支援いただけます。
                      </p>
                    </div>
                    <div className="home-methodTabs__cards -n2">
                      <p className="home-methodTabs__cardsTtl">
                        お支払い方法は下記から
                        <br className="u-sp" />
                        お選びいただけます。
                      </p>
                      <div className="home-methodTabs__cardsCols">
                        <div className="home-methodTabs__cardsColsCol">
                          <p className="-ttl">クレジットカード</p>
                          <p className="-txt1">お使いいただけるカード</p>
                          <p className="-cards">
                            <img
                              src="/assets/img/common/icon-cards.png"
                              alt=""
                            />
                          </p>
                        </div>
                        <div className="home-methodTabs__cardsColsCol">
                          <p className="-ttl">口座振替</p>
                          <p className="-txt1">
                            各種銀行口座（ゆうちょ銀行含む）
                          </p>
                          <p className="-txt2">
                            ＊ご登録の住所に手続き用紙をお送りします。
                            <br />
                            記入・捺印のうえ10日以内に
                            <br className="u-sp" />
                            ご返送をお願いします。
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="home-methodTabs__btn -mb">
                      <p className="c-btn -bl -home">
                        <Link href="/entry">
                          <a
                            target="_blank"
                            className="u-over"
                            rel="noreferrer"
                          >
                            「直接のご寄付」に申し込む
                          </a>
                        </Link>
                      </p>
                    </div>

                    <div className="home-pTtl2">
                      <h3 className="home-pTtl2__txt">
                        <span>ふるさと納税による寄付</span>
                      </h3>
                    </div>

                    <p className="home-methodTabs__furubnr">
                      <a
                        href="https://www.furusato-tax.jp/city/usage/41001/301"
                        className="u-over"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="u-pc"
                          src="/assets/img/home/method-furubnr.png"
                          alt="ふるさとチョイス"
                        />
                        <img
                          className="u-sp"
                          src="/assets/img/home/method-furubnr-sp.png"
                          alt="ふるさとチョイス"
                        />
                      </a>
                    </p>

                    <div className="home-methodTabs__txt">
                      <p>
                        ご都合の良い時に、ご自由な金額・回数でご寄付いただけます。
                        <br />
                        手続きによりふるさと納税による寄付額から2,000円を超える部分について、
                        <br className="u-pc" />
                        その年の所得税から還付されたり、その翌年の住民税から原則全額控除されます。
                        <br />
                        ご寄付のうち手数料10％を除いた90％が弊財団に寄付されます。
                        <br />
                        <br />
                        2021 年7
                        月に、地域みらい留学の推進のため弊団体の佐賀事務所を開設し、
                        <br className="u-pc" />
                        佐賀県のふるさと納税を通して直接寄付いただける仕組みを導入しました。
                        <br />
                        ※佐賀事務所立ち上げの背景は、
                        <a href="" target="_blank" className="u-linkLine2">
                          こちら
                        </a>
                        からご確認いただけます
                      </p>
                    </div>

                    <div className="home-methodTabs__btn -mt">
                      <p className="c-btn -gn -home">
                        <a
                          href="https://www.furusato-tax.jp/city/usage/41001/301"
                          target="_blank"
                          className="u-over"
                          rel="noreferrer"
                        >
                          「ふるさと納税」に申し込む
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="home-methodTabs__tabCon">
                  <div className="home-methodTabs__tabConInner">
                    <h2>遺贈・その他支援</h2>

                    <div className="home-methodTabs__etcCols">
                      <div className="home-methodTabs__etcColsCol">
                        <p className="home-methodTabs__etcColsImg">
                          <img
                            src="/assets/img/home/method-tab3-img1.jpg"
                            alt=""
                          />
                        </p>
                        <p className="home-methodTabs__etcColsTtl">
                          ご遺志を受け継ぐ
                          <br />
                          「遺贈・相続財産の寄付」
                        </p>
                        <p className="home-methodTabs__etcColsTxt">
                          遺言書を作成し遺産を寄付する遺贈や、
                          <br className="u-pc" />
                          相続財産のご寄付を受付ております。
                        </p>
                        <p className="c-btn -bn -thin">
                          <a href="/pdf/bequest.pdf" target="_blank">
                            PDF
                          </a>
                        </p>
                      </div>
                      <div className="home-methodTabs__etcColsCol">
                        <p className="home-methodTabs__etcColsImg">
                          <img
                            src="/assets/img/home/method-tab3-img2.jpg"
                            alt=""
                          />
                        </p>
                        <p className="home-methodTabs__etcColsTtl">
                          法人としての寄付や
                          <br />
                          CSRを検討している方
                        </p>
                        <p className="home-methodTabs__etcColsTxt">
                          資金援助のほか、CSR活動の協働についても
                          <br className="u-pc" />
                          お気軽にお問い合わせください。
                        </p>
                        <p className="c-btn -bn -thin">
                          <a
                            href="https://c-platform.or.jp/contact/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            お問い合わせ
                          </a>
                        </p>
                      </div>
                      <div className="home-methodTabs__etcColsCol">
                        <p className="home-methodTabs__etcColsImg">
                          <img
                            src="/assets/img/home/method-tab3-img3.jpg"
                            alt=""
                          />
                        </p>
                        <p className="home-methodTabs__etcColsTtl">
                          オンライン寄付サイト
                          <br />
                          「Give One」
                        </p>
                        <p className="home-methodTabs__etcColsTxt">
                          団体への寄付、領収証発行等はすべて
                          <br className="u-pc" />
                          （公財）パブリックリソース財団が行います。
                        </p>
                        <p className="c-btn -bn -thin">
                          <a
                            href="https://giveone.net/supporter/project_display.html?project_id=10570"
                            target="_blank"
                            rel="noreferrer"
                          >
                            もっと詳しく
                          </a>
                        </p>
                      </div>
                      <div className="home-methodTabs__etcColsCol">
                        <p className="home-methodTabs__etcColsImg">
                          <img
                            src="/assets/img/home/method-tab3-img4.jpg"
                            alt=""
                          />
                        </p>
                        <p className="home-methodTabs__etcColsTtl">
                          「地域みらい留学
                          <br />
                          応援自動販売機」
                        </p>
                        <p className="home-methodTabs__etcColsTxt">
                          自動販売機による飲料売上の一部を
                          <br className="u-pc" />
                          寄付することができます。
                        </p>
                        <p className="c-btn -bn -thin">
                          <a href="/pdf/vending_machine.pdf" target="_blank">
                            PDF
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="home-methodTabs__tabBtn -bottom">
                <li>
                  <a data-num="1" className="is-cr" href="javascript:void(0);">
                    毎月の寄付
                  </a>
                </li>
                <li>
                  <a data-num="2" className="" href="javascript:void(0);">
                    今回の寄付
                  </a>
                </li>
                <li>
                  <a data-num="3" className="" href="javascript:void(0);">
                    遺贈・その他
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="home-support2">
        <div className="l-container">
          <div className="home-support2__inner">
            <h2 className="home-support2__ttl">私たちも応援しています！</h2>
            <p className="home-support2__txt1">
              卒業生や保護者、各界の有志が
              <br />
              地域みらい留学の取り組みを
              <br className="u-sp" />
              応援してくれています。
            </p>

            <div className="home-support2__cols">
              <div className="home-support2__colsCol">
                <p className="home-support2__colsImg">
                  <img src="/assets/img/home/support2-img1.jpg" alt="" />
                </p>
                <div className="home-support2__colsTxts">
                  <p>衆議院議員</p>
                  <h3>小泉 進次郎</h3>
                </div>
              </div>
              <div className="home-support2__colsCol">
                <p className="home-support2__colsImg">
                  <img src="/assets/img/home/support2-img2.jpg" alt="" />
                </p>
                <div className="home-support2__colsTxts">
                  <p>
                    (株)New Stories代表
                    <br />
                    前総務省政策アドバイザー
                  </p>
                  <h3>太田 直樹</h3>
                </div>
              </div>
              <div className="home-support2__colsCol">
                <p className="home-support2__colsImg">
                  <img src="/assets/img/home/support2-img3.jpg" alt="" />
                </p>
                <div className="home-support2__colsTxts">
                  <p>
                    (公社)日本プロサッカーリーグ
                    <br />
                    (Jリーグ)チェアマン
                  </p>
                  <h3>村井 満</h3>
                </div>
              </div>
            </div>

            <p className="home-support2__txt1">
              設立時からの応援者
              <br className="u-sp" />
              「地域みらい留学応援10,000人委員会」の
              <br className="u-sp" />
              みなさま
            </p>
            <div className="home-support2__btn">
              <p className="c-btn -bl -home">
                <a
                  href="https://c-platform.or.jp/message/"
                  target="_blank"
                  className="u-over"
                  rel="noreferrer"
                >
                  応援者を見る
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-thanks">
        <div className="l-container">
          <div className="home-thanks__inner">
            <div className="home-pTtl3">
              <h2 className="home-pTtl3__txt">
                <span>ご支援いただいた方へ</span>
              </h2>
            </div>

            <div className="home-thanks__cols">
              <div className="home-thanks__colsCol">
                <h3>応援メッセージ掲載</h3>
                <p className="home-thanks__colsImg">
                  <img src="/assets/img/home/thanks-img1.jpg" alt="" />
                </p>
                <div className="home-thanks__colsTxts">
                  <p>
                    地域みらい留学への応援メッセージを弊財団ホームページに掲載します。
                  </p>
                </div>
              </div>
              <div className="home-thanks__colsCol">
                <h3>定期的なお便り</h3>
                <p className="home-thanks__colsImg">
                  <img src="/assets/img/home/thanks-img2.jpg" alt="" />
                </p>
                <div className="home-thanks__colsTxts">
                  <p>
                    地域みらい留学生の声や弊財団の活動報告を定期的にお届けします。
                  </p>
                </div>
              </div>
              <div className="home-thanks__colsCol">
                <h3>交流会へのご招待</h3>
                <p className="home-thanks__colsImg">
                  <img src="/assets/img/home/thanks-img3.jpg" alt="" />
                </p>
                <div className="home-thanks__colsTxts">
                  <p>
                    地域みらい留学を応援してくださる皆様や弊財団スタッフとの交流イベントにご招待します。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-faq">
        <div className="l-container">
          <div className="home-faq__inner">
            <div className="home-pTtl3">
              <h2 className="home-pTtl3__txt">
                <span>よくいただく質問</span>
              </h2>
            </div>

            <div className="home-faq__qa">
              <dl>
                <dt>Q.使用できるクレジットカードは？</dt>
                <dd>
                  VISA、 Master、 JCB、 American Express、 Diners
                  clubからお選びいただけます。
                </dd>
              </dl>
              <dl>
                <dt>Q.1回のみの寄付はできますか？</dt>
                <dd>
                  「今回のご寄付」として、ご都合の良い時に、ご自由な金額・回数で承っております。
                  <Link href="/entry">
                    <a>こちらのフォーム</a>
                  </Link>
                  からお手続きください。
                </dd>
              </dl>
              <dl>
                <dt>Q.寄付金はどのように使われますか？</dt>
                <dd>
                  「地域みらい留学」を始めとする一般財団法人地域・教育魅力化プラットフォームの活動全般に活用いたします。
                </dd>
              </dl>
              <dl>
                <dt>Q.領収証はもらえますか？</dt>
                <dd>
                  ご希望の方には毎年1月に前年度分のご寄付について寄付金受領証明書（領収書）を発行いたします。
                </dd>
              </dl>
              <dl>
                <dt>Q.寄付金額変更や退会の手続きはできますか？</dt>
                <dd>退会手続きは月ごとにいつでも可能です。</dd>
              </dl>
              <dl>
                <dt>Q.税制優遇はありますか？</dt>
                <dd>
                  一般財団法人ですので、ふるさと納税を除き個人寄付の場合は所得控除又は税額控除の対象とはなりません。また、法人寄付の場合、一般損金算入限度額の対象となりますが、別枠の損金算入限度額の対象とはなりません。あらかじめご了承ください。
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </section>

      <section className="home-greeting">
        <div className="l-container">
          <div className="home-greeting__inner">
            <div className="home-pTtl3">
              <h2 className="home-pTtl3__txt">
                <span>代表挨拶</span>
              </h2>
            </div>

            <div className="home-greeting__cols">
              <p className="home-greeting__colsImg">
                <img src="/assets/img/home/greeting-img1.jpg" alt="" />
              </p>
              <div className="home-greeting__colsTxts">
                <h3>
                  一般財団法人 <br className="u-sp" />
                  地域・教育魅力化プラットフォーム
                  <br />
                  代表理事 <b>岩本　悠</b>
                </h3>
                <p>
                  日頃より弊財団の活動に様々なご支援・ご協力をいただき誠にありがとうございます。
                  <br />
                  地域・教育魅力化プラットフォームの代表理事となりました岩本悠です。
                  <br />
                  <br />
                  2020年度は新型コロナウィルス感染症拡大による影響を受けながらも、地域みらい留学の合同説明会には前年を大幅に上回る1,728組3,456人の中学生・保護者等が参加され、この4月から多くの15歳が新たな地で新たな挑戦を開始しています。
                  <br />
                  <br />
                  また、内閣府と立ちあげた地域みらい留学365（高校2年生のときに一年間他地域の高校へ留学できる仕組み）も大きな反響を頂きながら、この春から第一期生が全国の地域へと旅立ち、日本初の国内での高校留学がいよいよ始まりました。
                  <br />
                  <br />
                  地域への越境的な学びが、新たなそして大きな流れになっていく手応えを感じた一年でした。
                  <br />
                  <br />
                  本年度は、我々自身を開き進化させる「第二創業の年」と捉え、様々な試行錯誤と新たな挑戦を皆さまとしていきたいと考えています。
                  <br />
                  <br />
                  意志ある若者にあふれる持続可能で幸せな未来づくりに向けぜひご一緒できれば幸いです。
                  <br />
                  本年度、さらなるご指導ご鞭撻をどうぞよろしくお願い致します。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
